<template>
  <div>
    <div class="container">
      <div class="wrapper">
        <Header
          title="線上和解書服務"
          backto="/"
          notes="注意，PAMO的線上和解書，僅適用於無人受傷且雙方皆不需動用保險理賠的情況。若您需要出險，請您聽從保險公司的指示。"
          link="線上和解書使用說明"
          url="https://www.pamolaw.com/pamo%e8%bb%8a%e7%a6%8d%e7%b7%9a%e4%b8%8a%e5%92%8c%e8%a7%a3%e6%9b%b8-%e6%93%8d%e4%bd%9c%e6%95%99%e5%ad%b8/"
        />
        <Menu :menu="menu" />
        <div class="text-center p-2">
          <a href="./withdraw-doc.pdf" download> <b-icon icon="cloud-download" />下載撤回告訴狀 </a>
          <p><small>有人受傷時使用</small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Menu from '@/components/Menu.vue';

export default {
  data() {
    return {
      menu: [
        {
          name: '開始製作和解書',
          id: 'newContract',
          target: 'record/create',
          highlighted: true,
        },
        {
          name: '繼續製作和解書',
          id: 'editContract',
          target: 'record',
          highlighted: true,
        },
        {
          name: '未完成的和解書',
          id: '',
          target: 'record',
          highlighted: false,
        },
        {
          name: '已完成的和解書',
          id: '',
          target: 'contract',
          highlighted: false,
        },
      ],
    };
  },
  components: {
    Header,
    Menu,
  },
};
</script>
